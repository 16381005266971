<template>
  <div class="LiveOrder_wrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="直播自签订单" name="1"></el-tab-pane>
      <el-tab-pane label="直播贡献订单" name="2"></el-tab-pane>
    </el-tabs>
    <div class="select_wrap">
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入订单ID"
        v-model="LiveOrderInfo.tradeNo"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入客户姓名"
        v-model="LiveOrderInfo.userName"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入客户手机号"
        v-model="LiveOrderInfo.mobile"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10 mr10"
        :clearable="true"
        placeholder="请输入归属人姓名"
        v-model="LiveOrderInfo.blUserName"
        style="width:180px"
      ></el-input>
      <el-select v-model="LiveOrderInfo.brandId" placeholder="品牌商名称" class="mr10">
        <el-option v-for="item in BrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="LiveOrderInfo.status" placeholder="转单状态" class="mr10">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
      <el-button type="primary" class="ml10" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="secondsKill_table" :data="LiveOrderList" border stripe style="width: 100%">
      <el-table-column prop="tradeNo" label="订单ID" fixed="left" width="120"></el-table-column>
      <el-table-column prop="payAmount" label="订单金额" width="120"></el-table-column>
      <el-table-column prop="itemName" label="商品名称"  width="120"></el-table-column>
      <el-table-column prop="brandName" label="品牌名称" width="120"></el-table-column>
      <el-table-column prop="userId" label="客户ID" width="120"></el-table-column>
      <el-table-column prop="userName" label="客户姓名" width="120"></el-table-column>
      <el-table-column prop="userMobile" label="手机号" width="120"></el-table-column>
      <el-table-column prop="blUserName" label="业绩归属人" width="120"></el-table-column>
      <el-table-column prop="blShopName" label="业绩归属门店" width="120"></el-table-column>
      <el-table-column prop="blBrandName" label="业绩归属品牌" width="120"></el-table-column>
      <el-table-column prop="userNameView" label="浏览推荐员工" width="120"></el-table-column>
      <el-table-column prop="userNameEnrollment" label="报名推荐员工" width="120"></el-table-column>
      <el-table-column prop="userNameCard" label="售卡推荐员工" width="120"></el-table-column>
      <el-table-column prop="userNameLive" label="直播推荐员工" width="120"></el-table-column>
      <el-table-column prop="createTime" label="下单时间" width="120"></el-table-column>
      <el-table-column prop="selfBonusAmount" label="签单奖励发放金额" width="120"></el-table-column>
      <el-table-column label="转单状态" width="90">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status=='active'">未转单</el-tag>
          <el-tag type="primary" v-if="scope.row.status=='finish'">已转单</el-tag>
          <el-tag type="primary" v-if="scope.row.status=='cancel'">已退单</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="finishTime" label="转单时间" width="120"></el-table-column>
      <el-table-column prop="changeAmount" label="转单金额" width="120"></el-table-column>
      <el-table-column prop="otherBonusAmount" label="转单奖励发放金额" width="80"  fixed="right"></el-table-column>
    </el-table>
    <div class="tab-page">
      <div></div>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="LiveOrderInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="LiveOrderTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "LiveOrder", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      activeName: "1",
      statusOptions: [
        { value: "active", label: "未转单" },
        { value: "finish", label: "已转单" },
        { value: "cancel", label: "已退单" },
      ],
      LiveOrderInfo: {
        actId: 0,
        blUserName: "",
        brandId: "",
        mobile: "",
        orderType: "1",
        page: 1,
        size: 10,
        status: "",
        tradeNo: "",
        userName: "",
      },
      LiveOrderList: [],
      LiveOrderTotal: 0,
      BrandList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 切换直播订单类型
    handleClick() {
      this.LiveOrderInfo = {
        actId: 0,
        blUserName: "",
        brandId: "",
        mobile: "",
        orderType: "",
        page: 1,
        size: 10,
        status: "",
        tradeNo: "",
        userName: "",
      };
      this.LiveOrderInfo.orderType = this.activeName;
      this.getQueryLiveOrderList();
    },
    // 表格 页码请求
    handleCurrentChange(val) {
      this.LiveOrderInfo.page = val;
      this.LiveOrderInfo.orderType = this.activeName;
      this.getQueryLiveOrderList();
    },
    searchPage() {
      this.LiveOrderInfo.page = 1;
      this.LiveOrderInfo.orderType = this.activeName;
      this.getQueryLiveOrderList();
    },
    // 查询直播订单列表
    async getQueryLiveOrderList() {
      try {
        this.LiveOrderInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryLiveOrderList(this.LiveOrderInfo);
        this.LiveOrderList = list;
        this.LiveOrderTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const {
          data: { list },
        } = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        this.BrandList = list;
        console.log(this.BrandList);
        // 7.13暂无数据，需完成门店设置接口联调
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出直播订单列表
    async exportData() {
      try {
        const data = await GuideAjax.getExportLiveOrderList(this.LiveOrderInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        var title = "直播订单.xls";
        if (this.LiveOrderInfo.orderType == "1") {
          title = "直播自签订单.xls";
        } else if (this.LiveOrderInfo.orderType == "2") {
          title = "直播贡献订单.xls";
        }
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryLiveOrderList();
    this.getActivityBrandList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.LiveOrder_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
