var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LiveOrder_wrapper" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "直播自签订单", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "直播贡献订单", name: "2" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入订单ID" },
            model: {
              value: _vm.LiveOrderInfo.tradeNo,
              callback: function($$v) {
                _vm.$set(_vm.LiveOrderInfo, "tradeNo", $$v)
              },
              expression: "LiveOrderInfo.tradeNo"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入客户姓名" },
            model: {
              value: _vm.LiveOrderInfo.userName,
              callback: function($$v) {
                _vm.$set(_vm.LiveOrderInfo, "userName", $$v)
              },
              expression: "LiveOrderInfo.userName"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入客户手机号" },
            model: {
              value: _vm.LiveOrderInfo.mobile,
              callback: function($$v) {
                _vm.$set(_vm.LiveOrderInfo, "mobile", $$v)
              },
              expression: "LiveOrderInfo.mobile"
            }
          }),
          _c("el-input", {
            staticClass: "ml10 mr10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入归属人姓名" },
            model: {
              value: _vm.LiveOrderInfo.blUserName,
              callback: function($$v) {
                _vm.$set(_vm.LiveOrderInfo, "blUserName", $$v)
              },
              expression: "LiveOrderInfo.blUserName"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "mr10",
              attrs: { placeholder: "品牌商名称" },
              model: {
                value: _vm.LiveOrderInfo.brandId,
                callback: function($$v) {
                  _vm.$set(_vm.LiveOrderInfo, "brandId", $$v)
                },
                expression: "LiveOrderInfo.brandId"
              }
            },
            _vm._l(_vm.BrandList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "mr10",
              attrs: { placeholder: "转单状态" },
              model: {
                value: _vm.LiveOrderInfo.status,
                callback: function($$v) {
                  _vm.$set(_vm.LiveOrderInfo, "status", $$v)
                },
                expression: "LiveOrderInfo.status"
              }
            },
            _vm._l(_vm.statusOptions, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "secondsKill_table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.LiveOrderList, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "tradeNo",
              label: "订单ID",
              fixed: "left",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "payAmount", label: "订单金额", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "itemName", label: "商品名称", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "brandName", label: "品牌名称", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userId", label: "客户ID", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "客户姓名", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userMobile", label: "手机号", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "blUserName", label: "业绩归属人", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "blShopName", label: "业绩归属门店", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "blBrandName", label: "业绩归属品牌", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userNameView", label: "浏览推荐员工", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userNameEnrollment",
              label: "报名推荐员工",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "userNameCard", label: "售卡推荐员工", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "userNameLive", label: "直播推荐员工", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "下单时间", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "selfBonusAmount",
              label: "签单奖励发放金额",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "转单状态", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == "active"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("未转单")
                        ])
                      : _vm._e(),
                    scope.row.status == "finish"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已转单")
                        ])
                      : _vm._e(),
                    scope.row.status == "cancel"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已退单")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "finishTime", label: "转单时间", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "changeAmount", label: "转单金额", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "otherBonusAmount",
              label: "转单奖励发放金额",
              width: "80",
              fixed: "right"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("div"),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.LiveOrderInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.LiveOrderTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }